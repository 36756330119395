<template>
  <div ref="bangumiView">
	<loader :loading="false">
		<summary-card :class="{show:vif.isHover}"  :fitem="hover_item" :style="summary_card_style"></summary-card>
      <template>
        <div class="full-video-header">
          <label class="switch hoverOpa" style="z-index: 99;position:absolute;right:5vw;top:80px">
            <input type="checkbox" v-model="showPosterVideo">
            <span class="slider"></span>
          </label>
          <video ref="video" class="background-image index-full-video" autoplay="autoplay" muted="muted" loop="loop"
                :src="showPosterVideo ? $constant.favoriteVideo[0] : ''"
                :poster="$constant.background.bangumi">
          </video>
        </div>
        <!-- 首页图片 -->
        <!-- <el-image style="animation: header-effect 2s"
                  class="background-image"
                  v-once
                  :src="$constant.background.bangumi"
                  fit="cover">
          <div slot="error" class="image-slot background-image-error"></div>
        </el-image> -->
        <!-- 首页文字 -->
        <div class="signature-wall myCenter my-animation-hideToShow">
          <h1 class="playful">
            <span v-for="(a, index) in '🎇分享追番列表🎇'" :key="index">{{a}}</span>
          </h1>
          <div class="printer" @click="getGuShi()">
            <printer :printerInfo="printerInfo">
              <template slot="paper" slot-scope="scope">
                <h3>
                  {{ scope.content }}<span class="cursor">|</span>
                </h3>
              </template>
            </printer>
          </div>
          <div id="bannerWave1"></div>
          <div id="bannerWave2"></div>
          <i class="el-icon-arrow-down" @click="navigation('.page-container-wrap')"></i>
        </div>
        <!-- 首页内容 -->
        <div class="page-container-wrap">
          <div class="page-container">
			<div class = "bv_container" >
				<div class="bv_font">🎃我的追番追剧🎃</div>
				<div class = "bv_block">
					<anime @mouseout.native="vif.isHover=false" @mouseover.native="ihover($event,item)"  v-for="(item,index) of list" :key="index" :fitem="item"></anime>
				</div>
				<!-- <div class="pageBlock"><li v-for="index of Math.ceil(anime.total / anime.limit)" :class="{pageBlock_selcted:pageIndex==index}" @click="getAnime(index,24)"  :key="'page1'+index">{{index}}</li></div> -->
			</div>
          </div>
		  <!-- <div class="pagination-wrap">
			<div @click="pageArticles()" class="pagination" v-if="pagination.total !== articles.length">
				下一页
			</div>
			<div v-else style="user-select: none">
				~~到底啦~~
			</div>
        </div> -->
		  <!-- <el-pagination
               layout="total, sizes,prev,pager,next,jumper"
               :page-size="pagination.size"
               :total="pagination.total"
               :current-page.sync="pagination.current"
               @current-change="getAnime(pagination.current,24)"
               @size-change="SizeChange"
               /> -->
        </div>
        <!-- 页脚 -->
        <div style="background: var(--background)">
          <myFooter></myFooter>
        </div>
      </template>
	</loader>
  </div>
</template>

<script>
import anime from '../components/common/anime.vue'
// import game from '../card/game.vue'
const summaryCard = () => import( "./common/summary");
const printer = () => import( "./common/printer");
const myFooter = () => import( "./common/myFooter");
import {getShow,bangu_getCollection,bangu_getCollections} from '../utils/api.js' 
import  {navigation} from '../utils/tools.js'
export default {
  components:{
	anime,
	myFooter,
	printer,
	"summary-card":summaryCard
  },
  name: 'MyAnime',
  data () {
    return {
		loading: false,
        showAside: true,
        printerInfo: "观众眼中的热爱最为炽热",
        pagination: {
          current: 1,
          size: 10,
          total: 0,
          keywords: ""
        },
        guShi: {
          "content": "观众眼中的热爱最为炽热.",
          "origin": "",
          "author": "",
          "category": ""
        },
        showPosterVideo:true,
        summary_card_style:{}, //用来定位hover卡片
        hover_item:{subject:{}},
        vif:{bangumiTv:true,isHover:false},
        list:[],
        flag:true,
        anime:{data:[],total:42,limit:24,pageIndex:1},
        pageIndex:1,
        loadtips:"Loading...",
    }
  },
	mounted(){
    this.$store.commit("changePlaylist", 436692330);
		let thi = this;
		let timer;
		this.getAnime(1,24);
		this.$nextTick(()=>{
			document.onscroll = function(e){
        if(!thi.$refs.bangumiView) return
				let now = document.body.scrollTop || document.documentElement.scrollTop;
				let clientHeight = window.screen.height;
				let scrollHeight = document.body.scrollHeight;
        console.log(now,scrollHeight-clientHeight);
				if(now>=(scrollHeight-clientHeight-200)&&thi.flag){
					thi.pageIndex += 1;
					thi.getAnime(thi.pageIndex,24);
				} 
			};
		});	
	},
  	created(){

  	},
  	methods:{
		navigation(target){
			navigation(target);
		},
		getAnime(pageIndex,num){
			let that = this;
			this.flag = false;
			if(pageIndex-1>Math.ceil(this.anime.total / this.anime.limit)){ return;}
			bangu_getCollection({subject_type:2,limit:num,offset:(pageIndex-1)*num},function(data){
				that.list.push(...data.data);
				that.flag = true;
			});
		},
		ihover(e,item){
			this.vif.isHover = true;
			this.hover_item = item;
			let offset = e.currentTarget.getBoundingClientRect();
			if(offset.x > window.screen.width/2) {
				this.summary_card_style.left = offset.x-240  + "px";
			} else {
				this.summary_card_style.left = offset.x + offset.width + "px";
			}
			this.summary_card_style.top = offset.y  + "px";
		},
		ifout(e){
			console.log(e);
		}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .signature-wall {
    /* 向下排列 */
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
    height: 100vh;
    overflow: hidden;
  }

  .playful {
    color: var(--white);
    font-size: 40px;
  }

  /*.playful span {*/
  /*  position: relative;*/
  /*  color: #5362f6;*/
  /*  text-shadow: 0.25px 0.25px #e485f8, 0.5px 0.5px #e485f8, 0.75px 0.75px #e485f8,*/
  /*  1px 1px #e485f8, 1.25px 1.25px #e485f8, 1.5px 1.5px #e485f8, 1.75px 1.75px #e485f8,*/
  /*  2px 2px #e485f8, 2.25px 2.25px #e485f8, 2.5px 2.5px #e485f8, 2.75px 2.75px #e485f8,*/
  /*  3px 3px #e485f8, 3.25px 3.25px #e485f8, 3.5px 3.5px #e485f8, 3.75px 3.75px #e485f8,*/
  /*  4px 4px #e485f8, 4.25px 4.25px #e485f8, 4.5px 4.5px #e485f8, 4.75px 4.75px #e485f8,*/
  /*  5px 5px #e485f8, 5.25px 5.25px #e485f8, 5.5px 5.5px #e485f8, 5.75px 5.75px #e485f8,*/
  /*  6px 6px #e485f8;*/
  /*  animation: scatter 1.75s infinite;*/
  /*  font-weight: normal;*/
  /*}*/

  /*.playful span:nth-child(2n) {*/
  /*  color: #ed625c;*/
  /*  text-shadow: 0.25px 0.25px #f2a063, 0.5px 0.5px #f2a063, 0.75px 0.75px #f2a063,*/
  /*  1px 1px #f2a063, 1.25px 1.25px #f2a063, 1.5px 1.5px #f2a063, 1.75px 1.75px #f2a063,*/
  /*  2px 2px #f2a063, 2.25px 2.25px #f2a063, 2.5px 2.5px #f2a063, 2.75px 2.75px #f2a063,*/
  /*  3px 3px #f2a063, 3.25px 3.25px #f2a063, 3.5px 3.5px #f2a063, 3.75px 3.75px #f2a063,*/
  /*  4px 4px #f2a063, 4.25px 4.25px #f2a063, 4.5px 4.5px #f2a063, 4.75px 4.75px #f2a063,*/
  /*  5px 5px #f2a063, 5.25px 5.25px #f2a063, 5.5px 5.5px #f2a063, 5.75px 5.75px #f2a063,*/
  /*  6px 6px #f2a063;*/
  /*  animation-delay: 0.3s;*/
  /*}*/

  /*.playful span:nth-child(3n) {*/
  /*  color: #ffd913;*/
  /*  text-shadow: 0.25px 0.25px #6ec0a9, 0.5px 0.5px #6ec0a9, 0.75px 0.75px #6ec0a9,*/
  /*  1px 1px #6ec0a9, 1.25px 1.25px #6ec0a9, 1.5px 1.5px #6ec0a9, 1.75px 1.75px #6ec0a9,*/
  /*  2px 2px #6ec0a9, 2.25px 2.25px #6ec0a9, 2.5px 2.5px #6ec0a9, 2.75px 2.75px #6ec0a9,*/
  /*  3px 3px #6ec0a9, 3.25px 3.25px #6ec0a9, 3.5px 3.5px #6ec0a9, 3.75px 3.75px #6ec0a9,*/
  /*  4px 4px #6ec0a9, 4.25px 4.25px #6ec0a9, 4.5px 4.5px #6ec0a9, 4.75px 4.75px #6ec0a9,*/
  /*  5px 5px #6ec0a9, 5.25px 5.25px #6ec0a9, 5.5px 5.5px #6ec0a9, 5.75px 5.75px #6ec0a9,*/
  /*  6px 6px #6ec0a9;*/
  /*  animation-delay: 0.15s;*/
  /*}*/

  /*.playful span:nth-child(5n) {*/
  /*  color: #555bff;*/
  /*  text-shadow: 0.25px 0.25px #e485f8, 0.5px 0.5px #e485f8, 0.75px 0.75px #e485f8,*/
  /*  1px 1px #e485f8, 1.25px 1.25px #e485f8, 1.5px 1.5px #e485f8, 1.75px 1.75px #e485f8,*/
  /*  2px 2px #e485f8, 2.25px 2.25px #e485f8, 2.5px 2.5px #e485f8, 2.75px 2.75px #e485f8,*/
  /*  3px 3px #e485f8, 3.25px 3.25px #e485f8, 3.5px 3.5px #e485f8, 3.75px 3.75px #e485f8,*/
  /*  4px 4px #e485f8, 4.25px 4.25px #e485f8, 4.5px 4.5px #e485f8, 4.75px 4.75px #e485f8,*/
  /*  5px 5px #e485f8, 5.25px 5.25px #e485f8, 5.5px 5.5px #e485f8, 5.75px 5.75px #e485f8,*/
  /*  6px 6px #e485f8;*/
  /*  animation-delay: 0.4s;*/
  /*}*/

  /*.playful span:nth-child(7n) {*/
  /*  color: #ff9c55;*/
  /*  text-shadow: 0.25px 0.25px #ff5555, 0.5px 0.5px #ff5555, 0.75px 0.75px #ff5555,*/
  /*  1px 1px #ff5555, 1.25px 1.25px #ff5555, 1.5px 1.5px #ff5555, 1.75px 1.75px #ff5555,*/
  /*  2px 2px #ff5555, 2.25px 2.25px #ff5555, 2.5px 2.5px #ff5555, 2.75px 2.75px #ff5555,*/
  /*  3px 3px #ff5555, 3.25px 3.25px #ff5555, 3.5px 3.5px #ff5555, 3.75px 3.75px #ff5555,*/
  /*  4px 4px #ff5555, 4.25px 4.25px #ff5555, 4.5px 4.5px #ff5555, 4.75px 4.75px #ff5555,*/
  /*  5px 5px #ff5555, 5.25px 5.25px #ff5555, 5.5px 5.5px #ff5555, 5.75px 5.75px #ff5555,*/
  /*  6px 6px #ff5555;*/
  /*  animation-delay: 0.25s;*/
  /*}*/

  .printer {
    cursor: pointer;
    color: var(--white);
    background: var(--translucent);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #bannerWave1 {
    height: 84px;
    background: var(--bannerWave1);
    position: absolute;
    width: 200%;
    bottom: 0;
    z-index: 10;
    animation: gradientBG 120s linear infinite;
  }

  #bannerWave2 {
    height: 100px;
    background: var(--bannerWave2);
    position: absolute;
    width: 400%;
    bottom: 0;
    z-index: 5;
    animation: gradientBG 120s linear infinite;
  }

  /* 光标 */
  .cursor {
    margin-left: 1px;
    animation: hideToShow 0.7s infinite;
    font-weight: 200;
  }

  .el-icon-arrow-down {
    font-size: 40px;
    font-weight: bold;
    color: var(--white);
    position: absolute;
    bottom: 60px;
    animation: my-shake 1.5s ease-out infinite;
    z-index: 15;
    cursor: pointer;
  }

  .page-container-wrap {
    background: var(--background);
    position: relative;
	min-height: 100vh;
  }

  .page-container {
    display: flex;
    justify-content: center;
    width: 90%;
    padding: 0 0 40px 0;
    margin: 0 auto;
    flex-direction: row;
  }
  

  .pagination-wrap {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .pagination {
    padding: 13px 15px;
    border: 1px solid var(--lightGray);
    border-radius: 3rem;
    color: var(--greyFont);
    width: 100px;
    user-select: none;
    cursor: pointer;
    text-align: center;
  }
 
  .pagination:hover {
    border: 1px solid var(--themeBackground);
    color: var(--themeBackground);
    box-shadow: 0 0 5px var(--themeBackground);
  }

  @media screen and (max-width: 1100px) {
    .recent-posts {
      width: 100%;
    }

    .page-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {

    .page-container {
      /* 文章栏与侧标栏垂直排列 */
      flex-direction: column;
    }

    .aside-content {
      width: 100%;
      max-width: unset;
      float: unset;
      margin: 40px auto 0;
    }
  }


#BangumiView{
	color: var(--font-color);
	margin: auto;
	text-align:center;
	display:flex;
	width:100%;
}
.bv_container{
	width:100%;
}
.bv_font{
	font-size: 1.8rem;
	line-height: 3rem;
	height: 3rem;
    width: 100%;
	text-align: center;
}
.bv_block{
	position:relative;
	display: grid;
	flex-wrap: wrap;
	grid-gap: 20px;
	justify-content: center;
	grid-template-columns: repeat(6,160px);
    margin-top: 20px;
}
.bv_block::-webkit-scrollbar{
	display: none;
}
.pageBlock{
	display: flex;
    justify-content: center;
    text-align: center;
    height: 32px;
    list-style: none;
}
.pageBlock li {
    width: 32px;
    height: 32px;
    margin: 0 5px 0;
    line-height: 32px;
    color: var(--focus-color);
    background: #dc143c;
}
.pageBlock li.pageBlock_selcted,.pageBlock li:hover{
	background-color: var(--font-color);
	cursor: pointer;
} 
@media screen and (max-width: 768px) {
	.bv_block{
		position:relative;
		width:100%;
		max-height: 85vh;
		overflow-y:scroll;
		display: flex;
		flex-wrap: wrap;
		margin:0.8em auto;
		max-width: 340px;
	}
	
	h1 {
	font-size: 35px;
	}
}
</style>
